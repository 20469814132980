var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_vm._l((_vm.list),function(ref){
var title = ref.title;
var cover = ref.cover;
var list = ref.list;
return [_c('div',{staticClass:"col-12 col-lg-6 col-xl-5"},[_c('TitleSticky',{attrs:{"text":title,"cover":cover}})],1),_c('div',{staticClass:"col-12 col-lg-6 col-xl-6"},[_c('div',{staticClass:"list"},[_c('ul',_vm._l((list),function(ref){
var title = ref.title;
var name = ref.name;
var description = ref.description;
var github = ref.github;
var link = ref.link;
return _c('li',[_c('h3',{staticClass:"title"},[(name)?_c('router-link',{staticClass:"link",attrs:{"to":_vm.getRoute(name)}},[_vm._v(_vm._s(title)),_c('fa',{staticClass:"ml-2",attrs:{"icon":['fas', 'caret-right']}})],1):[_vm._v(_vm._s(title))]],2),_c('p',{staticClass:"description"},[_vm._v(_vm._s(description))]),_c('div',[(name)?_c('LinkIcon',{attrs:{"to":_vm.getRoute(name),"icon":['fas', 'book']}},[_vm._v("Detail")]):_vm._e(),(link)?_c('LinkIcon',{attrs:{"href":link,"icon":['fas', 'globe']}},[_vm._v("Demo")]):_vm._e(),(github)?_c('LinkIcon',{attrs:{"href":github,"icon":['fab', 'github']}},[_vm._v("GitHub")]):_vm._e()],1)])}),0)])])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }